import React from 'react';
import { Link } from 'react-router-dom';
import './ProductCard2.scss';

const ProductCard2 = ({ product }) => {
  console.log("product", product);
  const { id, title, price, MRP, image, productID, sellerID } = product;

  return (
    <div className="product-card2">
      <Link to={`/${sellerID}/${productID}`}>
        <div className="image-container">
          <img
            src={image[0]?.src || 'https://via.placeholder.com/220x220?text=Product+Image'}
            alt={title || 'Product Image'}
            className="product-image"
          />
          {MRP > price && <span className="discount-badge">{Math.round((MRP - price) / MRP * 100)}% OFF</span>}
        </div>
        <div className="product-info">
          <h3 className="product-title">{title}</h3>
          <div className="product-price">
            <span className="current-price">₹{price.toFixed(2)}</span>
            {MRP > price && <span className="original-price">${MRP.toFixed(2)}</span>}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ProductCard2;
