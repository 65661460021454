import React, { useState } from 'react';
import './Cart.scss';
import { useDispatch, useSelector } from 'react-redux';
import { changeCartVisibility } from '../../../redux/settingsRedux';
import { changeQuantity, removeFromCart } from '../../../redux/cartRedux';
import { useLocation, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import emptyCartImage from '../../../Graphics/EmptyCart.svg';
const Cart = () => {
    const [showPanel, setShowPanel] = useState(true);
    const cartItems = useSelector((state) => state.cart.products);
    const storeID = useSelector((state) => state.store?.storeID);
    const total = useSelector((state) => state.cart.total);
    const showCart = useSelector((state) => state.settings.showCart);
    const [emptyCartMessage, setEmptyCartMessage] = useState(false);
    // const showCart = true
    const dispatch = useDispatch()
    const navigate = useNavigate();
    console.log("cart Items",cartItems);
    const location = useLocation();
    //store_id and storeID are same
    const store_id = location.pathname.split("/")[1];
    const filteredCartItems = cartItems.filter(item => item.sellerID === store_id);

    const filteredTotal = filteredCartItems.reduce((sum, item) => sum + (item.unitPrice * item.qty), 0);

    const togglePanel = () => {
        setEmptyCartMessage(false)
        setShowPanel(!showPanel);
    };

    const addToCart = (item) => {
        // dispatch action to add item to cart
        togglePanel();
    };
    const handleCheckout = () => {
        if (filteredCartItems.length === 0) {
            setEmptyCartMessage(true);
            return;
        }

        const itemsString = filteredCartItems.map(item => `${item.productID}-${item.qty}`).join(',');
        dispatch(changeCartVisibility(!showCart));
        navigate(`/${store_id}/checkout/`);
    };

    return (
        <div>
            {/* {showCart && ( */}
            <div className="cart-page">
                {showPanel && (
                    <div className={`cart-panel ${showCart ? '' : 'hide'}`}>

                        <div className="cart-panel-header">

                            <h1>Shopping Cart</h1>
                            {/* <h1 onClick={() => dispatch(changeCartVisibility(!showCart))}>X</h1> */}
                            <CloseIcon
                                onClick={() => {
                                    dispatch(changeCartVisibility(!showCart));
                                    setEmptyCartMessage(false);
                                }}
                            />
                        </div>
                        {/*  */}
                        <div className="cart-panel-items">
                        {filteredCartItems.length === 0 ? (
                                <div className="cart-empty">
                                    <img src={emptyCartImage} alt="Empty Cart" />
                                    <p>Your Cart is Empty!</p>
                                </div>
                            ) : (

                                filteredCartItems.map(item => (
                                    <div className='cart__item' key={item._id}>
                                        <div className='cart__item-image'>
                                            <img src={item.image} alt={item.productName} />
                                        </div>
                                        <div className='cart__item-details'>
                                            <div className='cart__item-name'>{item.productName}</div>
                                            <div className='cart__item-price'>₹{item.unitPrice}</div>
                                            <div className='cart__item-discount'>Discount: {item.discount ? item.discount : 0}</div>
                                            <div className='cart__item-quantity'>
                                                <button onClick={() => {
                                                    if (item.qty > 1) {
                                                        dispatch(changeQuantity({ _id: item._id, productID: item.productID, newQty: item.qty - 1 }))
                                                    } else {
                                                        dispatch(removeFromCart(item._id))
                                                    }
                                                }}>-
                                                </button>
                                                <span>{item.qty}</span>
                                                <button onClick={() => dispatch(changeQuantity({ _id: item._id, productID: item.productID, newQty: item.qty + 1 }))}>+</button>
                                                <button onClick={() =>
                                                    // console.log(item)
                                                    dispatch(removeFromCart(item._id))}
                                                >Remove</button>
                                            </div>
                                        </div>
                                    </div>
                                )))}


                        </div>
                        <div className='cart__remarks'>

                            <div className='cart__subtotal'>
                                <div>Subtotal:</div>
                                <div>₹{filteredTotal}</div>
                            </div>
                            <div className="cart__button">

                                <button onClick={() => dispatch(changeCartVisibility(!showCart))} className='primaryButton-outline'>Continue Shopping</button>
                                <button onClick={handleCheckout} className='checkout-btn'>Checkout</button>
                            </div>
                            {emptyCartMessage && <div className="empty-cart-message">Your cart is empty. Please add items to proceed to checkout.</div>}
                        </div>
                    </div>
                )}
            </div>



        </div>
    )
}

export default Cart