import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './footer.scss';
import { publicRequest } from '../../../requestMethods';

const Footer = ({ footerData, sellerID }) => {
  const [policies, setPolicies] = useState([]);

  useEffect(() => {
    const fetchPolicies = async () => {
      try {
        const response = await publicRequest.get(`/policies/public/${sellerID}`);
        setPolicies(response.data);
        // console.log(response.data);
      } catch (error) {
        console.error('Error fetching policies:', error);
      }
    };

    fetchPolicies();
  }, [sellerID]);

  if (!footerData) {
    return null;
  }

  const { aboutUs, contactUs, followUs } = footerData;
  const { backgroundColor, headingColor, contentColor } = footerData.colorSettings;

  const footerStyle = {
    backgroundColor: backgroundColor,
    color: contentColor,
  };

  const headingStyle = {
    color: headingColor,
  };

  const socialLinkStyle = {
    color: contentColor,
  };
  const prodientLinkStyle = {
    ...socialLinkStyle,
    textDecoration: 'none',
  };


  return (
    <footer className="footer" style={footerStyle}>
      <div className="footer-container">
        {aboutUs.isActive && (
          <div className="footer-section">
            <h4 style={headingStyle}>About Us</h4>
            <p>{aboutUs.value}</p>
          </div>
        )}

        {contactUs.isActive && (
          <div className="footer-section">
            <h4 style={headingStyle}>Contact Us</h4>
            <p>Email: {contactUs.email}</p>
            <p>Phone: {contactUs.mobile}</p>
            {contactUs.operatingAddress && (
              <>
                <h4 style={headingStyle}>Operating Address</h4>
                <p>{contactUs.operatingAddress}</p>
              </>
            )}
          </div>
        )}

        {followUs.isActive && (
          <div className="footer-section">
            <h4 style={headingStyle}>Follow Us</h4>
            <div className="social-links">
              {followUs.socialMedia.map((social, index) => (
                <a
                  key={index}
                  href={social.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={socialLinkStyle}
                >
                  {social.name}
                </a>
              ))}
            </div>
          </div>
        )}

        {policies.length > 0 && (
          <div className="footer-section">
            <h4 style={headingStyle}>Policies</h4>
            <div className="policy-links">
              {policies.map((policy, index) => (
                <a
                  key={index}
                  href={`/${sellerID}/policies/${policy.type}`}
                  style={socialLinkStyle}
                >
                  {policy.type.charAt(0).toUpperCase() + policy.type.slice(1)} Policy
                </a>
              ))}
            </div>
          </div>
        )}
        {/* New section for Prodinent */}
        <div className="footer-section prodinent-section">
          <p style={socialLinkStyle}>
            Powered by <a href="https://prodinent.com" target="_blank" rel="noopener noreferrer" style={prodientLinkStyle}>Prodinent</a>
          </p>
          <p style={socialLinkStyle}>
            Empowering businesses with seamless e-commerce solutions
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
