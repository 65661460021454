
import React, { useEffect, useState } from 'react'
import Navbar from '../../components/store/Navbar/Navbar'
import Checkout from '../../components/store/Cart/Checkout/Checkout'
import Footer from '../../components/store/Footer/Footer'
import { useDispatch, useSelector } from 'react-redux'
import { publicRequest } from '../../requestMethods'
import { useLocation } from 'react-router-dom'
import { storeData } from '../../redux/storeRedux'
import Theme2 from '../../components/store/Cart/Checkout/Theme2'
import TrackOrder from '../../components/store/TrackOrder/TrackOrder'

const TrackOrderPage = () => {
    const location = useLocation();
    const dispatch = useDispatch()
    const sellerID = location.pathname.split("/")[1];
    const orderID = location.pathname.split("/")[3];

    const store_Data = useSelector((state) => state.store?.storeData);
    const [storeData, setStoreData] = useState()
    const [footerData, setFooterData] = useState()
    const [orderData, setOrderData] = useState()


    useEffect(() => {
        // console.log("use Effect");
        const fetchData = async () => {
            // console.log("Fetching store data...");

            try {
                const storeResponse = await publicRequest.get(`/seller/find/${sellerID}/public`, {
                    withCredentials: true,
                });

                const orderResponse = await publicRequest.get(`/order/find/${sellerID}/${orderID}/public`, {
                    withCredentials: true,
                });
                console.log("Order Data ", orderResponse.data);
                setOrderData(orderResponse.data)

                // console.log("Store Data ", storeResponse.data);
                setStoreData(storeResponse.data[0])
                setFooterData(storeResponse.data.store[0].footer)
                if (storeResponse) {
                    dispatch(storeData(storeResponse.data));
                }
            } catch (error) {
                // console.error("Error fetching store data:", error);
            }

        }
        if (!store_Data || store_Data.storeID !== sellerID) {
        }
        fetchData();

    }, [sellerID, store_Data, dispatch]);

    // useEffect(() => {
    //   console.log("sellerID in use eff", sellerID);

    // }, [sellerID])
    useEffect(() => {
        // console.log("Store:", store_Data);
        if (store_Data) {
            // console.log("FAV");

            document.title = store_Data?.storeName ? store_Data.storeName + " : Checkout" : 'Prodinent';


            const favicon = document.querySelector('link[rel="icon"]');
            if (favicon) {
                if (store_Data.navigationBar.logo) {
                    favicon.href = store_Data?.navigationBar.logo;
                }
            }
        }
    }, [store_Data]);
    return (
        <div>
            <Navbar
                navbarData={store_Data?.navigationBar}
                sellerID={store_Data?.storeID}
            />
            {orderData && (
                <TrackOrder orderData={orderData} />
            )}




            {/* <Footer footerData={store_Data.footer} /> */}
        </div>
    )
}

export default TrackOrderPage