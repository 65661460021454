// StoreSettingsForm.js
import React, { useEffect, useState } from 'react';
import { uploadImage } from '../../utils/Firebase';

const StoreSettingsForm = ({ storeData, handleInputChange, handleUpdate }) => {

  console.log("storeData", storeData);
  const [previewLogo, setPreviewLogo] = useState(storeData.logo || null);
  const [logoURL, setLogoURL] = useState(storeData.logo || null);
  const [loading, setLoading] = useState(false);
  const [storeID, setStoreID] = useState(storeData.storeID);


  const handleLogoChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setLoading(true);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewLogo(reader.result);
      };
      reader.readAsDataURL(file);

      try {
        const path = `${storeID}/Logo`;
        // const path = `store_logos/${storeID}`;
        const downloadURL = await uploadImage(file, path);
        console.log("downloadURL", downloadURL);
        setLogoURL(downloadURL);
        handleInputChange({
          target: { name: 'logo', value: downloadURL },
        });
      } catch (error) {
        console.error('Error uploading logo:', error);
        // Handle the error (e.g., show an error message to the user)
      }finally {
        setLoading(false);
      }
    }
  };

  const handleFormUpdate = () => {
    // Include the logo URL in the update
    handleUpdate({ ...storeData, logo: logoURL });
  };
  useEffect(() => {
    // Update the preview and logoURL when storeData.logo changes
    setPreviewLogo(storeData.logo);
    setLogoURL(storeData.logo);
  }, [storeData.logo]);

  return (
    <div className="store-settings-form">
      <div className="store-settings-form__group logo-upload">
        <h3 className="store-settings-form__label">Store Logo</h3>
        <div className="logo-preview">
          {previewLogo ? (
            <img src={logoURL} alt="Store Logo" className="logo-image" />
          ) : (
            <div className="logo-placeholder">
              <span>No logo uploaded</span>
            </div>
          )}
        </div>
        <label htmlFor="logo-upload" className="logo-upload-button">
          {previewLogo ? 'Change Logo' : 'Upload Logo'}
        </label>
        <input
          id="logo-upload"
          type="file"
          accept="image/*"
          onChange={handleLogoChange}
          style={{ display: 'none' }}
        />
      </div>
      <div className="store-settings-form__group">
        <h3 className="store-settings-form__label">Store Name</h3>
        <input
          className="store-settings-form__input"
          type="text"
          id="storeName"
          name="storeName"
          value={storeData.storeName}
          onChange={handleInputChange}
        />
      </div>
      <div className="store-settings-form__group">
        <h3 className="store-settings-form__label">Store About</h3>
        <textarea
          className="store-settings-form__input"
          id="storeAbout"
          name="storeAbout"
          value={storeData.storeAbout}
          onChange={handleInputChange}
        />
      </div>
      <div className="store-settings-form__group">
        <h3 className="store-settings-form__label">GST</h3>
        <input
          className="store-settings-form__input"
          type="text"
          id="gst"
          name="gst_tin"
          value={storeData.gst_tin}
          onChange={handleInputChange}
        />
      </div>
      <button className="store-settings-form__update-button" onClick={handleFormUpdate} disabled={loading}>
        Update
      </button>
    </div>
  );
};

export default StoreSettingsForm;
