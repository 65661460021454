import React, { useState } from 'react';
import './orderSuccessOverlay.scss';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
const OrderSuccessOverlay = ({ onClose, sellerID, orderID }) => {
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const navigate = useNavigate();
    const handleCopy = () => {
        navigator.clipboard.writeText(orderID);
        setOpenSnackbar(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    return (
        <div className="order-success-overlay">
            <div className="order-success-content">
                <CheckCircleOutlineIcon className="order-success-icon" />
                <h2>Order Placed Successfully!</h2>
                <p>Thank you for your purchase. Your order has been placed successfully.</p>
                <div className="order-id-container">
                    <span className="order-id-label">Order ID:</span>
                    <span className="order-id-value">{orderID}</span>
                    <Tooltip title="Copy Order ID">
                        <ContentCopyIcon className="copy-icon" onClick={handleCopy} />
                    </Tooltip>
                </div>
                <button onClick={() => {
                    navigate(`/${sellerID}`);
                }}

                    className="order-success-button">Continue Shopping</button>
            </div>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    Order ID copied to clipboard!
                </MuiAlert>
            </Snackbar>
        </div>
    );
};

export default OrderSuccessOverlay;
