import React, { useEffect, useState } from 'react'
import './checkout.scss'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation } from 'react-router-dom';
import { publicRequest } from '../../../../requestMethods';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import OrderSuccessOverlay from './OrderSuccessOverlay';
import { addProduct, clearCart } from '../../../../redux/cartRedux';
import Payment from '../Payment/Payment';
const Checkout = () => {
    const location = useLocation();
    const sellerID = location.pathname.split("/")[1]
    const cart = useSelector((state) => state.cart);
    const cartItems = useSelector((state) => state.cart.products);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const productIdWithQuantity = location.pathname.split("/")[3];
    const productID = productIdWithQuantity ? productIdWithQuantity.split('-')[0] : null;
    const productQuantity = productIdWithQuantity && productIdWithQuantity.split('-')[1] ? parseInt(productIdWithQuantity.split('-')[1], 10) : 1;

    // const productQuantity = productIdWithQuantity ? parseInt(productIdWithQuantity.split('-')[1], 10) : 1;

    const dispatch = useDispatch();
    // console.log("seller id", sellerID);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [orderSuccess, setOrderSuccess] = useState(false);
    const [orderID, setOrderID] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        pincode: '',
        addressLine1: '',
        addressLine2: '',
        landmark: ''
    });
    useEffect(() => {
        // console.log("1", productIdWithQuantity);
        // console.log("2", productID);
        // console.log("QTY Product", productQuantity);
        // console.log("cart items", cartItems);
        
        // console.log("QTY in link", productQuantity);
        const fetchProduct = async () => {
            if (productID) {
                // console.log("ProducID in link");
                setLoading(true);
                try {
                    const res = await publicRequest.get(`/product/${sellerID}/${productID}/public`, {
                        withCredentials: true,
                    });

                    const product = res.data;
                    // console.log("Product checkout page",res.data);
                    dispatch(clearCart())
                    dispatch(
                        addProduct({
                            _id: product._id,
                            productID: product.productID,
                            warehouseID: product.warehouseID,
                            productName: product.title,
                            unitPrice: product.price,
                            qty: productQuantity,
                            totalPrice: product.price * productQuantity,
                            image: product.image[0].src,
                            productWeight: product.productWeight,
                            sellerID: product.sellerID, 
                        })
                    );
                    setLoading(false);
                } catch (error) {
                    console.error('Error fetching product:', error);
                    if (error.response.statusText == "Not Found") {

                        setError('No Product found!');
                    } else {

                        setError('Error fetching product. Please try again.');
                    }
                    setLoading(false);
                    setOpenSnackbar(true);
                }
            }
        };
        fetchProduct();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    const validateForm = () => {
        if (!formData.name || !formData.email || !formData.mobile || !formData.pincode || !formData.addressLine1 || !formData.city || !formData.state) {
            setError('Please fill all the required fields.');
            setOpenSnackbar(true);
            return false;
        }
        return true;
    };
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const calculateTotalWeight = () => {
        return cartItems.reduce((total, item) => total + item.qty * item.productWeight, 0);
    };
    const handleSubmit = async (e) => {

        e.preventDefault();
        if (!validateForm()) return;

        if (cartItems.length === 0) {
            setError('No products in the cart');
            setOpenSnackbar(true);

            return;
        }
        const totalWeight = calculateTotalWeight();

        // console.log("cart items", cartItems);
        // console.log("Weight", totalWeight);
        setLoading(true);

        try {
            // Check if customer exists
            // console.log("customer");
            const customerResponse = await publicRequest.get('customer/check', {
                params: { email: formData.email, sellerID: sellerID },
            });

            let customerID;
            console.log("customer-1", customerResponse.data);
            if (customerResponse.status === 200) {
                // Customer exists
                customerID = customerResponse.data.customerID;
            } else if (customerResponse.data.message === 'Customer not found') {
                // console.log("new customer");
                // Customer does not exist, create new customer
                const newCustomer = {
                    sellerID: sellerID,
                    name: formData.name,
                    email: formData.email,
                    phone: formData.mobile,
                    address: {
                        street: `${formData.addressLine1}, ${formData.addressLine2}, ${formData.landmark}`,
                        city: formData.city,
                        state: formData.state,
                        postalCode: formData.pincode,
                        country: 'India',
                    },
                };
                const createCustomerResponse = await publicRequest.post('customer', newCustomer);
                customerID = createCustomerResponse.data.customerID;
                // console.log("new customer ID", customerID);
            }

            // Create order
            const order = {
                sellerID: sellerID,
                customerID: customerID,
                shippingAddress: {
                    name: formData.name,
                    email: formData.email,
                    phone: formData.mobile,
                    fullAddress: `${formData.addressLine1}, ${formData.addressLine2}, ${formData.landmark}`,
                    pincode: formData.pincode,
                    city: formData.city,
                    state: formData.state,
                    country: 'India',
                },
                status: "Order Placed",
                products: cartItems,
                amount: cart.total,
                deliveryCharge: 0,
                totalOrderValue: cart.total + 0,
                shipment: {
                    length: 26,
                    breadth: 26,
                    height: 1,
                    totalWeight: totalWeight,
                },
                orderTimeline: [
                    {
                        remarks: 'Order placed successfully',
                    },
                ],
            };

            const orderResponse = await publicRequest.post('order', order);
            // console.log('Order saved:', orderResponse.data);
            setLoading(false);
            setOrderID(orderResponse.data.orderID);
            dispatch(clearCart())

            setOrderSuccess(true);
            const whatsappres = await publicRequest.post('whatsapp/send-message',{sellerID});
            // console.log("Done WA",whatsappres.data);
            
        } catch (error) {
            console.error('Error saving order:', error);
            setLoading(false);
        }
    };

    const handleOverlayClose = () => {
        setOrderSuccess(false);
        // You can add more actions here, such as redirecting to the home page
    };



    return (
        <div className="checkout">
            {loading && (
                <>
                    <Backdrop
                        open
                        sx={{
                            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Set the background color with transparency
                            color: '#fff',
                            zIndex: (theme) => theme.zIndex.drawer + 1
                        }}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>

                </>
            )}
            <h1 className="checkout__title">Checkout</h1>
            <form className="checkout__form" onSubmit={handleSubmit}>
                <div className="checkout__form-group">
                    <label className="checkout__label">Name</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="checkout__input"
                    />
                </div>
                <div className="checkout__form-group">
                    <label className="checkout__label">Email</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="checkout__input"
                    />
                </div>
                <div className="checkout__form-group">
                    <label className="checkout__label">Mobile</label>
                    <input
                        type="text"
                        name="mobile"
                        value={formData.mobile}
                        onChange={handleChange}
                        className="checkout__input"
                    />
                </div>
                <div className="checkout__form-group">
                    <label className="checkout__label">Pincode</label>
                    <input
                        type="text"
                        name="pincode"
                        value={formData.pincode}
                        onChange={handleChange}
                        className="checkout__input"
                    />
                </div>
                <div className="checkout__form-group">
                    <label className="checkout__label">Flat, House no., Building, Company, Apartment</label>
                    <input
                        type="text"
                        name="addressLine1"
                        value={formData.addressLine1}
                        onChange={handleChange}
                        className="checkout__input"
                    />
                </div>
                <div className="checkout__form-group">
                    <label className="checkout__label">Area, Street, Sector, Village</label>
                    <input
                        type="text"
                        name="addressLine2"
                        value={formData.addressLine2}
                        onChange={handleChange}
                        className="checkout__input"
                    />
                </div>
                <div className="checkout__form-group">
                    <label className="checkout__label">Landmark</label>
                    <input
                        type="text"
                        name="landmark"
                        value={formData.landmark}
                        onChange={handleChange}
                        className="checkout__input"
                    />
                </div>
                <div className="checkout__form-group checkout__form-group--inline">
                    <div className="checkout__form-group-item">
                        <label className="checkout__label">Town/City</label>
                        <input
                            type="text"
                            name="city"
                            value={formData.city}
                            onChange={handleChange}
                            className="checkout__input"
                        />
                    </div>
                    <div className="checkout__form-group-item">
                        <label className="checkout__label">State</label>
                        <input
                            type="text"
                            name="state"
                            value={formData.state}
                            onChange={handleChange}
                            className="checkout__input"
                        />
                    </div>
                </div>
                {/* <Payment/> */}
                <button type="submit" className="checkout__submit-button">Submit</button>
            </form>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={4000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MuiAlert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                    {error}
                </MuiAlert>
            </Snackbar>
            {orderSuccess && <OrderSuccessOverlay orderID={orderID} sellerID={sellerID} onClose={handleOverlayClose} />}
        </div>
    )
}

export default Checkout