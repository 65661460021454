import React, { useEffect, useState } from 'react';
import PolicyEditor from './PolicyEditor';
import './PoliciesPage.scss';
import { sellerRequest } from '../../../requestMethods';
import { useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography, Box, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
const PoliciesPage = () => {
    const { sellerID } = useSelector((state) => state.seller.sellerData);
    const [loading, setLoading] = useState(false)
    const [policies, setPolicies] = useState({
        shipping: { enabled: false, content: '' },
        cancellation: { enabled: false, content: '' },
        return: { enabled: false, content: '' },
        privacy: { enabled: false, content: '' },
        terms: { enabled: false, content: '' },
    });
    const [editingPolicy, setEditingPolicy] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const policyNames = {
        shipping: 'Shipping & Delivery Policy',
        cancellation: 'Cancellation/Refund Policy',
        return: 'Return Policy',
        privacy: 'Privacy Policy',
        terms: 'Terms and Conditions',
    };
   const togglePolicy = async (policyType) => {
    setLoading(true);
    try {
        const updatedEnabled = !policies[policyType].enabled;
        let response;

        if (policies[policyType].content) {
            // If content exists, it's an update
            response = await sellerRequest.put(`/policies/update/${sellerID}`, {
                type: policyType,
                enabled: updatedEnabled,
                content: policies[policyType].content
            });
        } else {
            // If no content, it's a create
            response = await sellerRequest.post(`/policies/create/${sellerID}`, {
                type: policyType,
                enabled: updatedEnabled,
                content: '' // Initialize with empty content
            });
        }
        
        setPolicies(prev => ({
            ...prev,
            [policyType]: { ...prev[policyType], enabled: updatedEnabled, content: response.data.content }
        }));
        
        setSnackbarMessage('Policy updated successfully');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
    } catch (error) {
        console.error('Error updating policy:', error);
        setSnackbarMessage('Error updating policy');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
    } finally {
        setLoading(false);
    }
};

    const openPolicyEditor = (policyType) => {
        setEditingPolicy(policyType);
    };

    const closePolicyEditor = () => {
        setEditingPolicy(null);
    };
    

    const savePolicy = async (policyType, content) => {
        console.log(policyType, content);
        setLoading(true)
        try {
            let response;
            if (policies[policyType].content) {
                console.log("update")
                // If content exists, it's an update
                response = await sellerRequest.put(`/policies/update/${sellerID}`, {
                    type: policyType,
                    content,
                    enabled: true
                });
            } else {
                console.log("create")
                // If no content, it's a create
                response = await sellerRequest.post(`/policies/create/${sellerID}`, {
                    type: policyType,
                    content,
                    enabled: true
                });
            }
            console.log(response.data);

            // Update local state
            setPolicies(prev => ({
                ...prev,
                [policyType]: { ...prev[policyType], content, enabled: true }
            }));

            closePolicyEditor();
            setSnackbarOpen(true);
            setSnackbarMessage('Policy saved successfully');
            setSnackbarSeverity('success');
        } catch (error) {
            console.error('Error saving policy:', error);
            // Handle error (e.g., show error message to user)
            setSnackbarOpen(true);
            setSnackbarMessage('Error saving policy');
            setSnackbarSeverity('error');
        }finally{
            setLoading(false)
        }
    };

    const fetchPolicies = async () => {
        console.log(policies, "policies");
        try {
            const response = await sellerRequest.get(`/policies/get/${sellerID}`)
            console.log(response.data)
            const updatedPolicies = { ...policies };
            response.data.forEach(policy => {
                if (updatedPolicies.hasOwnProperty(policy.type)) {
                    updatedPolicies[policy.type] = {
                        enabled: policy.enabled,
                        content: policy.content
                    };
                }
            });
            setPolicies(updatedPolicies);

        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        fetchPolicies()
    }, [sellerID])

    return (
        <div className="policies-page">
            <h1>Store Policies</h1>
            <Box className="info-box" mb={3}>
                <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                    Setting up clear store policies is crucial for your e-commerce business.
                    <Tooltip title="Policies help build trust with customers and set clear expectations for your business operations.">
                        <InfoIcon fontSize="small" color="action" sx={{ ml: 1, cursor: 'pointer' }} />
                    </Tooltip>
                </Typography>
                <Typography variant="body2" color="textSecondary" mt={1}>
                    These policies:
                </Typography>
                <ul>
                    <li>Build trust with your customers</li>
                    <li>Set clear expectations for your business operations</li>
                    {/* <li>Are often required for integrating payment gateways and other e-commerce services</li> */}
                </ul>
                <Typography variant="body2" color="textSecondary" mt={1} fontWeight="bold">
                    Note: Setting up policies is mandatory when integrating with payment gateways.
                </Typography>
            </Box>
            {Object.entries(policies).map(([policyType, policy]) => (
                <div key={policyType} className="policy-item">
                    <div className="policy-header">
                        <h2>{policyNames[policyType]}</h2>
                        <div className="policy-toggle">
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    checked={policy.enabled}
                                    onChange={() => togglePolicy(policyType)}
                                />
                                <span className="slider"></span>
                            </label>
                        </div>
                    </div>
                    {policy.enabled && (
                        <div className="policy-actions">
                            <button className="edit-policy-btn" onClick={() => openPolicyEditor(policyType)}>
                                {policy.content ? 'Edit' : 'Create'}
                            </button>
                        </div>
                    )}
                </div>
            ))}
             {editingPolicy && (
                <PolicyEditor
                    policyType={policyNames[editingPolicy]}
                    initialContent={policies[editingPolicy].content}
                    onSave={(content) => savePolicy(editingPolicy, content)}
                    onClose={closePolicyEditor}
                />
            )}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            {loading && (

                <Backdrop
                    open
                    sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1
                    }}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

            )}  
        </div>
    );
};

export default PoliciesPage;