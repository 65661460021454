import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaPlus } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { Backdrop, CircularProgress, Snackbar, Alert, Box, Typography, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
// Adjust this import path as needed

import './CategoryManagement.scss';
import { uploadImage } from '../../../utils/Firebase';
import { sellerRequest } from '../../../requestMethods';
import { useSelector } from 'react-redux';

const CategoryManagement = () => {
  const { sellerID } = useSelector((state) => state.seller.sellerData);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [newCategory, setNewCategory] = useState({ name: '', description: '', image: '' });
  const [previewImage, setPreviewImage] = useState(null);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [imagePreview, setImagePreview] = useState(null);
  const [imageError, setImageError] = useState(null);

  useEffect(() => {
    fetchCategories();
  }, [sellerID]);

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const response = await sellerRequest.get(`/category/getall/${sellerID}/public`);
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCategorySelect = (event) => {
    const category = categories.find(cat => cat.categoryID === parseInt(event.target.value));
    setSelectedCategory(category);
    console.log(category);
    setImagePreview(category ? category.image : null);
    setIsAddingNew(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (selectedCategory) {
      setSelectedCategory({ ...selectedCategory, [name]: value });
    } else {
      setNewCategory({ ...newCategory, [name]: value });
    }
  };

  const handleImageUpload = async (file) => {
    setLoading(true);
    try {
      const path = `Seller Data/${sellerID}/Category Images`;
      const url = await uploadImage(file, path);
      console.log("Image uploaded successfully. URL:", url);

      // Update the category with the new image URL
      if (selectedCategory) {
        setSelectedCategory({ ...selectedCategory, image: url });
      } else {
        setNewCategory({ ...newCategory, image: url });
      }

    } catch (error) {
      console.error("Error uploading image:", error);
      setImageError("Failed to upload image");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (selectedCategory) {
        await sellerRequest.put(`/category/${sellerID}/${selectedCategory.categoryID}`, {
          name: selectedCategory.name,
          description: selectedCategory.description,
          image: selectedCategory.image
        });
        setSnackbarMessage('Category updated successfully');
      } else {
        await sellerRequest.post(`/category/${sellerID}`, { ...newCategory, sellerID });
        setSnackbarMessage('Category added successfully');
      }
      fetchCategories();
      setSelectedCategory(null);
      setNewCategory({ name: '', description: '', image: '' });
      setPreviewImage(null);
      setIsAddingNew(false);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error saving category:', error);
      setSnackbarMessage('Error saving category');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {

    if (!selectedCategory) return;
    setLoading(true);
    try {
      await sellerRequest.delete(`/category/${sellerID}/${selectedCategory.categoryID}`);
      fetchCategories();
      setSelectedCategory(null);
      setPreviewImage(null);
      setSnackbarMessage('Category deleted successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error deleting category:', error);
      setSnackbarMessage('Error deleting category');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleAddNew = () => {
    setIsAddingNew(true);
    setSelectedCategory(null);
    setNewCategory({ name: '', description: '', image: '' });
    setImagePreview(null);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    console.log("Selected file:", file);

    if (file) {
      if (file.size > 500 * 1024) {
        setImageError(`Image size (${(file.size / 1024).toFixed(2)}KB) exceeds 500KB limit. 
          Please compress your image and try again.`);
        setImagePreview(null);
        return;
      }

      const reader = new FileReader();
      reader.onloadend = async () => {
        // Create an image element to check dimensions
        const img = new Image();
        img.src = reader.result;

        img.onload = async () => {
          console.log(`Image dimensions: ${img.width}x${img.height}`);

          // Check if image is square (1:1 aspect ratio)
          if (img.width !== img.height) {
            setImageError(
              `Image must be square (1:1 aspect ratio). Current dimensions are ${img.width}x${img.height}px. 
            Recommended size: 250x250 pixels or larger.`
            );
            setImagePreview(null);
            return;
          }

          // If we get here, the image meets all requirements
          console.log("Image validation passed");
          setImagePreview(reader.result);
          setImageError(null);

          // Call handleImageUpload with the selected file
          await handleImageUpload(file);
        };

        img.onerror = () => {
          setImageError("Failed to load image. Please try another file.");
          setImagePreview(null);
        };
      };


      reader.onerror = () => {
        console.error("FileReader error:", reader.error);
        setImageError("Error reading file");
        setImagePreview(null);
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
      setImageError(null);
    }
  };

  return (
    <div className="category-management">
      <div className="category-management__header">
        <h2 className="category-management__title">Category Management</h2>
        <button
          className="category-management__add-button"
          onClick={handleAddNew}
        >
          <FaPlus />
        </button>
      </div>

      <Box className="info-box" mb={2}>
        <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', alignItems: 'center' }}>
          Create and manage your product categories.
          <Tooltip title="Categories help organize your products and make it easier for customers to find what they're looking for.">
            <InfoIcon fontSize="small" color="action" sx={{ ml: 1, cursor: 'pointer' }} />
          </Tooltip>
        </Typography>
        <Typography variant="body2" color="textSecondary" mt={1}>
          Important tips for creating categories:
        </Typography>
        <ul>
          <li><strong>Naming:</strong> Use clear, concise names that accurately describe the product group.</li>
          <li><strong>Hierarchy:</strong> Consider creating main categories and subcategories for better organization.</li>
          <li><strong>Consistency:</strong> Maintain a consistent naming convention across all categories.</li>
          <li><strong>Image requirements:</strong>
            <ul>
              <li>Dimensions: 250 x 250 pixels (1:1 aspect ratio)</li>
              <li>Maximum file size: 500KB</li>
              <li>Supported formats: JPEG, PNG</li>
            </ul>
          </li>
          <li><strong>Optimization:</strong> Use keywords in category names and descriptions to improve searchability.</li>
        </ul>
      </Box>


      {!isAddingNew &&
        <>
          <select
            className="category-management__select"
            value={selectedCategory ? selectedCategory.categoryID : ''}
            onChange={handleCategorySelect}
          >
            <option value="" disabled>Select a category</option>
            {categories.map((category) => (
              <option key={category.categoryID} value={category.categoryID}>
                {category.name}
              </option>
            ))}
          </select>
        </>
      }
      {(selectedCategory || isAddingNew) && (
        <div className="category-management__form">
          <input
            className="category-management__input"
            type="text"
            placeholder="Name"
            name="name"
            value={selectedCategory ? selectedCategory.name : newCategory.name}
            onChange={handleInputChange}
          />
          <input
            className="category-management__input"
            type="text"
            placeholder="Description"
            name="description"
            value={selectedCategory ? selectedCategory.description : newCategory.description}
            onChange={handleInputChange}
          />
          <div className="category-management__image-upload">
            <input
              type="file"
              id="category-image"
              className="category-management__image-upload-input"
              onChange={handleImageChange}
              accept="image/*"
            />
            <label htmlFor="category-image" className="category-management__image-upload-label">
              <FontAwesomeIcon icon={faUpload} /> Upload Image
            </label>
            <div className="category-management__image-preview">
              {imagePreview ? (
                <img
                  src={imagePreview}
                  alt="Category preview"
                  className="category-management__image-preview-img"
                  onError={() => {
                    console.error("Image failed to load");
                    setImageError("Failed to load image");
                  }}
                />
              ) : (
                <PhotoCameraIcon className="category-management__image-preview-icon" />
              )}
            </div>
            {imageError && <p className="category-management__image-error">{imageError}</p>}
          </div>
          <div className="category-management__actions">
            <button
              onClick={handleSubmit}
              className="category-management__button category-management__button--primary"
            >
              {selectedCategory ? 'Update' : 'Save'} Category
            </button>
            {selectedCategory && (
              <button
                onClick={handleDelete}
                className="category-management__button category-management__button--secondary"
              >
                Delete Category
              </button>
            )}
          </div>
        </div>
      )}
      {loading && (
        <Backdrop
          open
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CategoryManagement;
