import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './PolicyEditor.scss';

const PolicyEditor = ({ policyType, initialContent, onSave, onClose }) => {
    const [content, setContent] = useState(initialContent);

    const handleSave = () => {
        onSave(content);
    };

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ['link'],
            [{ 'color': [] }, { 'background': [] }],
            ['clean']
        ],
    };

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike',
        'list', 'bullet',
        'link',
        'color', 'background'
    ];

    return (
        <div className="policy-editor-overlay">
            <div className="policy-editor">
                <h2>{policyType.charAt(0).toUpperCase() + policyType.slice(1)} Policy</h2>
                <ReactQuill
                    theme="snow"
                    value={content}
                    onChange={setContent}
                    modules={modules}
                    formats={formats}
                />
                <div className="editor-actions">
                    <button onClick={handleSave}>Save</button>
                    <button onClick={onClose}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default PolicyEditor;