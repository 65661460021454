import React, { useState, useEffect, useRef } from 'react';
import { DateRangePicker } from 'react-date-range';
import { format } from 'date-fns';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { sellerRequest } from '../../requestMethods';
import { useSelector } from 'react-redux';
import './orderAnalytics.scss';

const OrderAnalytics = () => {
    const { sellerID } = useSelector((state) => state.seller.sellerData);
    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [analytics, setAnalytics] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const datePickerRef = useRef(null);
    const dateRangeSpanRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (datePickerRef.current && !datePickerRef.current.contains(event.target) &&
                dateRangeSpanRef.current && !dateRangeSpanRef.current.contains(event.target)) {
                setShowDatePicker(false);
            }
        };

        if (showDatePicker) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showDatePicker]);

    const fetchAnalytics = async () => {
        setLoading(true);
        setError(null);
        const formattedStartDate = format(dateRange[0].startDate, 'yyyy-MM-dd');
        const formattedEndDate = format(dateRange[0].endDate, 'yyyy-MM-dd');

        try {
            const response = await sellerRequest.get(`/analytics/orders/${sellerID}?startDate=${formattedStartDate}&endDate=${formattedEndDate}`);
            setAnalytics(response.data);
        } catch (err) {
            setError(err.response?.data?.message || 'An error occurred while fetching analytics');
        } finally {
            setLoading(false);
        }
    };

    // Helper function to safely format numbers
    const formatNumber = (value) => {
        return value != null ? value.toFixed(2) : 'N/A';
    };

    return (
        <div className='orderAnalytics'>
            <div className='orderAnalytics__container'>
                <div className='orderAnalytics__header'>
                    <h2>Order Analytics</h2>
                    <div className='orderAnalytics__dateRange'>
                        <span 
                            ref={dateRangeSpanRef}
                            onClick={() => setShowDatePicker(!showDatePicker)}
                        >
                            {format(dateRange[0].startDate, 'dd MMM yyyy')} - {format(dateRange[0].endDate, 'dd MMM yyyy')}
                        </span>
                        <button onClick={fetchAnalytics} disabled={loading}>
                            {loading ? 'Loading...' : 'Get Analytics'}
                        </button>
                    </div>
                    {showDatePicker && (
                        <div 
                            className='orderAnalytics__dateRangePicker' 
                            ref={datePickerRef}
                            style={{
                                position: 'absolute',
                                top: dateRangeSpanRef.current ? dateRangeSpanRef.current.offsetTop + dateRangeSpanRef.current.offsetHeight : 0,
                                left: dateRangeSpanRef.current ? dateRangeSpanRef.current.offsetLeft : 0,
                            }}
                        >
                            <DateRangePicker
                                onChange={item => setDateRange([item.selection])}
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                ranges={dateRange}
                                direction="horizontal"
                            />
                        </div>
                    )}
                </div>

                {error && <div className='orderAnalytics__error'>{error}</div>}

                {analytics && (
                    <div className='orderAnalytics__content'>
                        <h3>Analytics for {format(dateRange[0].startDate, 'MMM dd, yyyy')} - {format(dateRange[0].endDate, 'MMM dd, yyyy')}</h3>
                        
                        <div className='orderAnalytics__summary'>
                            <div className='summary-card'>
                                <h4>Total Orders</h4>
                                <p>{analytics.summary?.totalOrders || 0}</p>
                            </div>
                            <div className='summary-card'>
                                <h4>Total Revenue</h4>
                                <p>₹{formatNumber(analytics.summary?.totalRevenue)}</p>
                            </div>
                            <div className='summary-card'>
                                <h4>Total Profit</h4>
                                <p>₹{formatNumber(analytics.summary?.totalProfit)}</p>
                            </div>
                        </div>

                        <div className='orderAnalytics__productSales'>
                            <h3>Product Sales</h3>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Product Name</th>
                                        <th>Quantity Sold</th>
                                        <th>Total Revenue</th>
                                        <th>Total Profit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {analytics.productSales?.map((product) => (
                                        <tr key={product.name}>
                                            <td>{product.name}</td>
                                            <td>{product.quantitySold || 0}</td>
                                            <td>₹{formatNumber(product.totalRevenue)}</td>
                                            <td>₹{formatNumber(product.totalProfit)}</td>
                                        </tr>
                                    )) || <tr><td colSpan="4">No product sales data available</td></tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default OrderAnalytics;
