// MessagePlatform.js
import React, { useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import './MessagePlatform.scss';

function MessagePlatform() {
  const [contacts] = useState([
    { id: 1, name: 'Mahenrasinghchouhan' },
    { id: 2, name: 'Rishana Riyas' },
    // Add more contacts
  ]);

  const [messages] = useState({
    1: [
      { from: 'Mahenrasinghchouhan', text: 'Hello! Can I get more info on this?' },
      { from: 'You', text: 'Welcome to Saplings, We\'re happy to help you!' },
      { from: 'Mahenrasinghchouhan', text: 'Hello! Can I get more info on this?' },
    ],
    2: [
      { from: 'Rishana Riyas', text: 'Hi there!' },
      { from: 'You', text: 'Hello! What can I do for you?' },
    ],
    // Add more messages
  });

  const [selectedContact, setSelectedContact] = useState(null);
  const [newMessage, setNewMessage] = useState('');

  const selectContact = (contact) => {
    setSelectedContact(contact);
  };

  const sendMessage = () => {
    if (newMessage.trim() && selectedContact) {
      messages[selectedContact.id].push({ from: 'You', text: newMessage });
      setNewMessage('');
    }
  };

  return (
    <div className="message-platform">
      <div className="contact-list">
        <input type="text" placeholder="Search here" />
        <ul>
          {contacts.map(contact => (
            <li key={contact.id} onClick={() => selectContact(contact)}>
              {contact.name}
            </li>
          ))}
        </ul>
      </div>
      <div className="message-viewer">
        {selectedContact ? (
          <>
            <div className="header">
              <h2>{selectedContact.name}</h2>
            </div>
            <ul className="messages">
              {messages[selectedContact.id].map((msg, index) => (
                <li key={index} className={msg.from === 'You' ? 'message right' : 'message left'}>
                  <p>
                    <strong>{msg.from}:</strong> {msg.text}
                  </p>
                </li>
              ))}
            </ul>
            <div className="message-input">
              <input
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Send Message or type '/' for Quick Message..."
              />
              <button onClick={sendMessage}>
                <SendIcon />
              </button>
            </div>
          </>
        ) : (
          <p>Select a contact to view messages</p>
        )}
      </div>
    </div>
  );
}

export default MessagePlatform;