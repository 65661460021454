import React, { useEffect, useState } from 'react'
import Navbar from '../../components/store/Navbar/Navbar'
import Footer from '../../components/store/Footer/Footer'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { publicRequest } from '../../requestMethods'
import './PolicyPage.scss';
const PolicyPage = () => {
    const location = useLocation();
    const params = useParams();
    const dispatch = useDispatch()
    const sellerID = location.pathname.split("/")[1];
    const [storeData, setStoreData] = useState()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [policy, setPolicy] = useState(null)
    useEffect(() => {

        const fetchData = async () => {
            try {
                const storeResponse = await publicRequest.get(`/seller/find/${sellerID}/public`, {
                    withCredentials: true,
                });
                // console.log("Store Data ", storeResponse.data);

                setStoreData(storeResponse.data.store[0])
                // if (storeResponse) dispatch(storeData(storeResponse.data[0]));
                const policyResponse = await publicRequest.get(`/policies/${params.policyType}/${sellerID}`);
                setPolicy(policyResponse.data)
                setLoading(false)
            } catch (error) {
                console.error('Error fetching data:', error)
                setError('Failed to load policy. Please try again later.')
                setLoading(false)
            }
        }

        fetchData()
    }, [sellerID])




    return (
        <div className="policy-page">
            {storeData && (

                <Navbar
                    navbarData={storeData.navigationBar}
                    sellerID={storeData.storeID}
                />
            )}
            {policy && (
                <div className="policy-content">
                    <h1>{policy.type.charAt(0).toUpperCase() + policy.type.slice(1)} Policy</h1>
                    <div dangerouslySetInnerHTML={{ __html: policy.content }} />
                </div>
            )}

            {storeData && (

                <Footer footerData={storeData.footer} sellerID={storeData.storeID} />
            )}
        </div>
    )
}

export default PolicyPage